let resumeData = {
  "imagebaseurl": "",
  "name": "pradip sapkota",
  "role": "",
  "linkedinId": "",
  "skypeid": "",
  "roleDescription": "",
  "socialLinks": [
    {
      "name": "Twitter",
      "url": "https://twitter.com/PradipSap12",
      "className": "fa fa-twitter"
    }, 
    {
      "name": "Facebook",
      "url": "https://www.facebook.com/pra.dip.92167789",
      "className": "fa fa-facebook"
    },  
    {
      "name": "tiktok",
      "url": "#aa",
      "className": "fa fa-brands fa-tiktok"
    },  
    {
      "name": "Gmail",
      "url": "mailto:pradipabcd123@gmail.com",
      "className": "fa fa-envelope"
    }

  ],
  "aboutme": "I believe that to be successful in life, one needs to be obsessive with their dreams and keep working towards them.",
  "address": "Bharatpur-06,Chitwan,Nepal",
  "website": "pradipsapkota.info.np",
  "phone":"+977-9864067288",
  "education": [
    
    {
      "UniversityName": "Narayani MaVi",
      "specialization": "Diploma in IT(DIT)",
      "MonthOfPassing": "2020",
      "YearOfPassing": "- 2023",
    },
  ],
  
  "skillsDescription": "",
  "skills": [
    {
      "skillname": "Communication",
      "description":"Language : Nepali,English,Hindi"
    },
    {
      "skillname": "Organisational skills",
      "description":"Ability : Technical ability,Quick Learner"
    },
    {
      "skillname": "Job-related skills",
      "description":"Goal-oriented and well-organized"
    },
    {
      "skillname": "Digital skills",
      "description":"Microsoft Office, Email and Internet, Social communication"
    }
  ],
  "portfolio": [
    {
      "name": "project1",
      "description": "mobileapp",
      "imgurl": "images/portfolio/phone.jpg"
    },
    {
      "name": "project2",
      "description": "mobileapp",
      "imgurl": "images/portfolio/project.jpg"
    },
    {
      "name": "project3",
      "description": "mobileapp",
      "imgurl": "images/portfolio/project2.png"
    },
    {
      "name": "project4",
      "description": "mobileapp",
      "imgurl": "images/portfolio/phone.jpg"
    }
  ],
  "testimonials": [
    {
      "description": "This is a sample testimonial",
      "name": "Some technical guy"
    },    {
      "description": "This is a sample testimonial",
      "name": "Some technical guy"
    },
    {
      "description": "This is a sample testimonial",
      "name": "Some technical guy"
    }
  ]
}

export default resumeData